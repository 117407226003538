<template>
  <div>
    <ViewTemplateWithTable :panel="panel" :templateList="templateList" :buttonRemove="false" :propsParam="propsParam">
      <div slot="content-filter-horizontal">
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="3" xl="3">
            <SelectStatic title="Situação" :initialValue="select" :initialFieldTarget="select" fieldTarget="Status"
              :showBtnClean="true" :data="[
                { content: 'Em atraso', id: 'Em atraso' },
                { content: 'A vencer', id: 'A vencer' },
                { content: 'Liquidado', id: 'Liquidado' },
                { content: 'Rejeitado', id: 'Rejeitado' },
              ]" :markFormDirty="true" v-model="select" />
          </b-col>
        </b-row>
      </div>
    </ViewTemplateWithTable>
  </div>
</template>

<script>

import ViewTemplateWithTable from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithTable.vue";
import SelectStatic from "@nixweb/nixloc-ui/src/component/forms/SelectStatic";

import { mapGetters, mapMutations, mapActions, mapState } from "vuex";

export default {
  name: "BilletListView",
  components: {
    ViewTemplateWithTable, SelectStatic
  },
  data() {
    return {
      id: "",
      panel: {
        module: "Boleto Bancário",
        title: "Boleto",
        showFilter: true,
        showSearch: true,
        showButtons: true,
      },
      templateList: {
        urlGetApi: "/api/v1/billing/billet/get-all-status",
        showChecks: false,
        headerTable: [
          {
            title: "Emissão",
            field: "registeredDate",
            type: "dateTime",
            iconSearch: false,
          },
          {
            field: "customerName",
            title: "Cliente",
            type: "text",
            iconSearch: true,
          },
          {
            title: "Vencimento",
            field: "dueDate",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
          {
            field: "statusName",
            title: "Situação",
            type: "class",
            fieldComparison: "statusName",
            classCssTitle: "text-center",
            classCssBody: [
              {
                classCss: "text-center badge badge-primary",
                fieldComparison: "A vencer",
              },
              {
                classCss: "text-center badge badge-dark-primary",
                fieldComparison: "Registrado",
              },
              {
                classCss: "text-center badge badge-success",
                fieldComparison: "Liquidado",
              },
              {
                classCss: "text-center badge badge-danger",
                fieldComparison: "Cancelado",
              },
              {
                classCss: "text-center badge badge-danger",
                fieldComparison: "Rejeitado",
              },
              {
                classCss: "text-center badge badge-warning",
                fieldComparison: "Baixa",
              },
              {
                classCss: "text-center badge badge-danger",
                fieldComparison: "Em atraso",
              },
            ],
          },
          {
            title: "Valor",
            field: "value",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            type: "currency",
          },
          {
            type: "button",
            typeButton: "default",
            classCssTitle: "text-center",
            classCssBody: "text-center",
            button: {
              classIcon: "fa-regular fa-copy",
              type: "default",
              size: "small",
              eventName: "copyLink",
            },
          },
        ],
      },
      filter: this.$route.params.filter,
      status: this.$route.params.status,
      select: { "content": "", "id": "" },
      propsParam: {
        filter: "",
        status: ""
      },
    };
  },
  created() {
    if (this.filter) {
      if (this.filter == "delayed") this.propsParam.filter = "Pendente";
      if (this.filter == "today") this.propsParam.filter = "Hoje";
      if (this.filter == "week") this.propsParam.filter = "Semana Atual";
      if (this.filter == "month") this.propsParam.filter = "Mês Atual";
      if (this.filter == "year") this.propsParam.filter = "Ano Atual";
    }

    if (this.status) {
      let status = "";
      if (this.status == "emitted") status = "Emitido";
      if (this.status == "registered") status = "Registrado";
      if (this.status == "delayed") status = "Em atraso";
      if (this.status == "paid") status = "Liquidado";

      return this.select = { "content": status, "id": status };
    }

  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
    ...mapState("generic", ["selected"]),
  },
  methods: {
    ...mapMutations("generic", ["openModal", "hideModal", "removeLoading"]),
    ...mapActions("generic", ["putAllApi"]),
    confirmCancel() {
      this.removeLoading(["btnCancelBillet"]);
      this.openModal("cancelModal");
    },
    cancel() {
      let params = {
        url: this.templateList.urlCancel,
        selected: this.selected,
      };
      this.putAllApi(params).then((response) => {
        if (response.success) {
          this.hideModal("generateInvoice");
        }
        this.removeLoading(["confirm"]);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "copyLink") {
          navigator.clipboard.writeText(event.data.link);
          this.$toasted.show("Link copiado...", { type: "info" });
        }
      },
      deep: true,
    },
    select: {
      handler(select) {
        this.propsParam.status = select.id;
      },
      deep: true,
    },
  },
};
</script>
<style>
.icon-warning {
  color: darkorange;
}

.div-tag {
  margin-top: 10px;
  margin-bottom: 20px;
}
</style>